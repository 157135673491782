
.RightPannel {
  position:relative;
  width:calc(30% - 0.5vw);
  min-height:2vw;
  padding:0.1vw 0.25vw;
  @media (max-width: 767px) {
    width:100%;
    min-height:5vw;
  }

  .DayName {
    min-height:1.2vw;
    @media (max-width: 767px) {
      min-height:calc(5vw - 2px);
    }
  }

  .DayNameWeek {
    min-height:2.5vw;
    @media (max-width: 767px) {
      min-height:calc(5vw - 2px);
    }
  }

  .DayName, .DayNameWeek {
    width:calc((100% / 7) - 4px);
    margin:0 1px;
    background-color: #111;
    border:1px solid #fff;
    border-radius: 0.5vw;
  }

  .SameMonth {opacity:0.4;}
  .HolyDay {background-color:#f002;}
  .Today {
    background-color:#f60;
    border-color:#fff;
    &:hover {
      cursor:pointer;
      background-image:linear-gradient(0deg, #1111, #1115 40% 60%, #1111);
    }
  }

  .DocRight {
    width:100%;
    margin:0.25vw 0 0 0;

    .DocBefore, .DocAfter {
      min-height:2.5vw;
      border-top:1px solid #9991;
      border-bottom:1px solid #9991;
    }

    .DocBody {
      position:relative;
      width:calc(100% - 2px);
      min-height:2.5vw;
      border-radius:0.25vw;
      @media (max-width: 767px) {
        min-height:5vw;
      }
    }
  }
}