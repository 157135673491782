
.Header {
  position:relative;
  min-height:4vw;
  z-index:99;
  @media (min-width: 481px) and (max-width: 767px) {min-height:6vw;}
  @media (max-width: 481px) {min-height:8vw;}

  .SiteName {
    width:calc(25% - 1vw);
    @media (min-width: 481px) and (max-width: 767px) {width:calc(80% - 1vw)}
    @media (max-width: 481px) {width:calc(75% - 1vw)}

    .Name {
      font-size:200%;
      opacity: 1;
      animation: fade 2s ease-in-out infinite;
      @keyframes fade {
        0% {opacity: 1;}
        25% {opacity: 1;}
        90% {opacity: 0;}
        95% {opacity: 0;}
        100% {opacity: 1;}
      }
    }
  }

  .TelNr {
    width:15%;
    font-size:120%;
    @media (max-width: 767px) {width:40%;}
  }

  .NavBurger {
    display:none;
    @media (min-width: 481px) and (max-width: 767px) {width:6vw; display:flex;}
    @media (max-width: 481px) {width:8vw; display:flex;}

    .Burger {

      .BurgerLine {
        width:100%;
        height:0.5vw;
        margin:0.25vw;
        background:#f60;
        border-radius:0.25vw;
      }
    }
  }

  .NavSection {
    width:50%;
    @media (max-width: 767px) {display:none;}

    .NavPannel {
      width:100%;
  
      .NavBtn {
        position:relative;
        width:15%;
        cursor: pointer;
  
        .activeNavBtn {
          width:100%;
          margin:0 0 0.5vw 0;
          text-align:center;
          font-size:110%;
          font-weight:bold;
          color:#f60;
          border-bottom:1px solid #444;
        }
      }
  
      .SubMenu {
        position:absolute;
        top:1.25vw;
        width:100%;
        padding:0.5vw;
        background-color:#333;
  
        .SubNavBtn {
          width:100%;
          margin:0.25vw 0;
          &:hover a {
            color:#191;
          }
  
          .activeSubNavBtn {
            color:#fd0;
            font-size:110%;
            font-weight:bold;
          }
        }
      }
    }
  }

  .NavPannel {
    width:100%;

    .NavBtn {
      position:relative;
      width:70%;
      margin:1vw 0;
      cursor: pointer;
      @media (max-width: 767px) {font-size:140%;}

      .activeNavBtn {
        width:100%;
        margin:1.5vw 0;
        text-align:center;
        font-size:110%;
        font-weight:bold;
        color:#f60;
        border-bottom:1px solid #444;
      }
    }

    .SubMenu {
      position:absolute;
      top:3.5vw;
      width:70%;
      padding:0.5vw;
      background-color:#333;
      z-index:99;
      @media (min-width: 481px) and (max-width: 767px) {top:5vw;}
      @media (max-width: 481px) {top:7vw;}

      .SubNavBtn {
        width:100%;
        margin:1vw 0;
        &:hover a {
          color:#191;
        }

        .activeSubNavBtn {
          color:#fd0;
          font-size:110%;
          font-weight:bold;
        }
      }
    }
  }

  .UserPannel {
    position:relative;
    width:calc(10% - 1vw);
    @media (max-width: 767px) {width:calc(30% - 1vw);}
    
    .Ava {
      position:absolute;
      border:1px solid #aaa;
      border-radius:50%;
    }

    .Online {
      position:absolute;
      opacity: 1;
      animation: fader 1s ease-in-out infinite;
      @keyframes fader {
        0% {opacity: 1;}
        70% {opacity: 1;}
        80% {opacity: 0;}
        90% {opacity: 0;}
        100% {opacity: 1;}
      }
    }
  }

  .FullScreenPannel {

    .BlurPannel {
      position:absolute;
      top:0;
      right:0;
      width:100%;
      min-height:100%;
      z-index:55;
    }
      
    .SidePannel {
      position:fixed;
      top:0;
      right:0;
      width:35%;
      min-height:100%;
      background-image:linear-gradient(90deg, #000, #000c, #000);
      z-index:66;
      @media (min-width: 481px) and (max-width: 767px) {width:60%;}
      @media (max-width: 481px) {width:90%;}

      .SidePannelTop {
        width:calc(100% - 2vw);
        min-height:4vw;
        padding:0 1vw;
        border-image: linear-gradient(90deg, #9991, #999) 1;
        border-bottom: 1px solid;
        @media (min-width: 481px) and (max-width: 767px) {min-height:6vw;}
        @media (max-width: 481px) {min-height:8vw;}

        .LangPannel {
          width:40%;
          margin:0 0.5vw;

          .ImgBtn, .ImgBtnSmall {
            margin:0 0.5vw;
          }
        }
      }

      .AuthBottomPannel {
        position:absolute;
        width:95%;
        flex:1;
        bottom:2vw;
    
        .Logout {
          width:40%;
          padding:0.5vw 0;
          border: 1px solid #f00;
          border-radius: 0.5vw;
          background-color:#f001;
          cursor: pointer;
          &:hover { background-color: #f002; }
          @media (max-width: 767px) {
            padding:1vw 0;
            border-radius:1vw;
          }

          span {flex:1;}
        }
      }
    }
  }
}