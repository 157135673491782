body {
  margin:0;
	padding:0;
	font-size:1vw;
	color:#fff;
  font-family: 'Quicksand', sans-serif;
  // font-family: -apple-system, 'Roboto', sans-serif;
  // BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Roboto', 'Ubuntu',
  //'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
  @media (min-width: 481px) and (max-width: 767px) {font-size: 2vw !important;}
  @media (max-width: 481px) {font-size: 3vw !important;}
  @media print {font-size: 8px !important; background-image:none;}

  .txtWht {color:#fff;}
  .txtOrg {color:#f60;}
  .txtYlw {color:#fd0;}
  .txtGrn {color:#1a1;}
  .txtRed {color:#f00;}
  .txtBlk {color:#000;}
  .txtGry {color:#999;}
  .txtBlu {color:#7391f5;}

  .bold	{font-weight:bold;}
  .underline {text-decoration: underline;}

  .none     {display:none;}
  .flex 		{display:flex; justify-content:center; align-items:center;}
  .wrap 		{flex-wrap:wrap;}
  .stretch 	{align-items:stretch;}
  .column		{flex-direction:column;}
  .start		{justify-content:flex-start;}
  .end			{justify-content:flex-end;}
  .between	{justify-content:space-between;}
  .evenly	  {justify-content:space-evenly;}
  .around	  {justify-content:space-around;}

  .blur {filter:blur(5px);}

  .radius {
    border-radius:0.5vw;
    @media (min-width: 481px) and (max-width: 767px) { border-radius:1vw; }
    @media (max-width: 481px) { border-radius:1vw; }
  }

  .overflow {
    overflow: hidden; /* Ukryj zawartość, która wychodzi poza element */
    text-overflow: ellipsis; /* Pokaż "..." na końcu obciętego tekstu */
    white-space: nowrap; /* Zapobiegaj zawijaniu tekstu */
  }

  .Radius {
    border-radius:0.5vw;
    @media (min-width: 481px) and (max-width: 767px){border-radius:0.75vw;}
    @media (max-width: 481px){border-radius:1vw;}
  }
  
  .ImgBtn {
    width:2vw; height:2vw; margin:0.25vw 0.75vw;
    &:hover {width:2.5vw; height:2.5vw; margin:0 0.5vw;}
    @media (min-width: 481px) and (max-width: 767px) {
      width:4vw; height:4vw; margin:0.5vw 1vw;
      &:hover {width:5vw; height:5vw; margin:0 0.5vw;}
    }
    @media (max-width: 481px) {
      width:6vw; height:6vw; margin:0.5vw 1vw;
      &:hover {width:7vw; height:7vw; margin:0 0.5vw;}
    }
  }
  .ImgBtnSmall {
    width:1.5vw; height:1.5vw; margin:0.25vw 0.75vw;
    &:hover {width:2vw; height:2vw; margin:0 0.5vw;}
    @media (min-width: 481px) and (max-width: 767px) {
      width:3vw; height:3vw; margin:0.5vw 1vw;
      &:hover {width:4vw; height:4vw; margin:0 0.5vw;}
    }
    @media (max-width: 481px) {
      width:4vw; height:4vw; margin:0.5vw 1vw;
      &:hover {width:5vw; height:5vw; margin:0 0.5vw;}
    }
  }

  .BtnGrn {
    background-color:#1719;
    border:1px solid #999;
    border-radius:0.5vw;
    &:hover {
      border-color:#fff;
      background-image:linear-gradient(#1915, #191, #1915);
    }
  }
  .BtnRed {
    background-color:#f009;
    border:1px solid #999;
    border-radius:0.5vw;
    &:hover {
      border-color:#fff;
      background-image:linear-gradient(#f005, #f00, #f005);
    }
  }

  a {text-decoration:none; color:#fff;}

  .BoxShadow {
    &:hover{box-shadow: 0 0 0.5vw 0.5vw #999;}
  }

  .App {
    display: flex;
    flex-direction: column;
    width:100%;
    min-height: 100vh;
  }

  .CookiesLink {
    flex:1;
    padding:0 1vw;

    a {
      color: #0af;
      text-decoration: none;
      &:hover {text-decoration: underline;}
    }
  }
}


// 320px (dla bardzo małych urządzeń mobilnych)
// 480px (dla urządzeń mobilnych)
// 768px (dla tabletów i mniejszych laptopów)
// 992px (dla większych laptopów i komputerów stacjonarnych)
// 1200px (dla dużych monitorów)

// @media (max-width: 480px) {
//   /* Your styles for mobile devices */
// }
// @media (min-width: 481px) and (max-width: 768px) {
//   /* Your styles for tablets and smaller laptops */
// }
// @media (min-width: 769px) {
//   /* Your styles for large monitors */
// }
// @media print {
//   /* Your styles for printing */
// }