
.TopLine {
  width:calc(100% - 2vw);
  padding:0.5vw 1vw 0 1vw;
  
  .NavLine {
    width:50%;

    .NavEl {
      padding:0;

      .NavElLink {
        padding:0 0.5vw;
        color:#fd0;
        &:hover {
          color:#f60;
        }
      }
    }
  }

  .BtnsPannel {
    width:50%;
    min-height:3vw;
  }
}