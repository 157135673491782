
.InputText {
  width:calc(100% - 1vw);
  padding:0.25vw 0.5vw;

  .Legend {
    width:calc(100% - 1vw);
    font-size:80%;
    margin:0 0 0 1vw;
    color:#1c1;
  }

  input {
    width:calc(100% - 1vw);
    padding:0.25vw 0.5vw;
    border:none;
    outline: none;
    color:#fff;
    font-size:100%;
    background-color: transparent;
    border-bottom:1px solid #555;
    &:focus {
      background-image:linear-gradient(180deg, transparent 50%, transparent 50%, #1c13 100%);
      border-color:#1c1;
      border-radius:0.25vw;
    }
  }
}