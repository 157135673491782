
.TagH {
  width:100%;
  min-height:2vw;
  color:#f60;
  background-color:#222;
  background-image:linear-gradient(90deg, #1111, #111, #1111);
  border-top:1px solid #555;
  border-bottom:1px solid #555;

  .TagHInputs {
    width:100%;

    .Input {
      width:calc((100% / 3) - 1vw - 2px);
      margin:0 0.25vw 0.5vw 0.25vw;
      @media (max-width: 767px){width:calc(100% - 1vw - 2px);}
    }
  }
}