
.NoCompaniesWarning {
  position:absolute;
  top:0;
  width:calc(50% - 4vw - 2px);
  margin:1vw;
  padding:1vw;
  border-radius:0.5vw;
  background-color:#111e;
  box-shadow: 0 0 0.5vw 0.5vw #f60;
  z-index:10;

  .CloseWarningBtn {
    position:absolute;
    top:0;
    right:0;
  }

  span {
    width:80%;
    margin:0.5vw 0;
    text-indent:2vw;
    font-size:130%;
  }
}