
.Input {
  margin-top: 0.5vw;
  padding:0.4vw 0.2vw 0.2vw 0.2vw;
  border:1px solid #555;
  border-radius:0.5vw;

  legend {
    font-size:80%;
    margin:0 0.5vw;
    padding:0 0.5vw;
  }

  input {
    margin:0;
    padding:0.25vw 0.5vw;
    width:calc(100% - 1vw);
    color:#fff;
    background-color:transparent;
    border:none;
    border-radius:0.25vw;
  }

  select {
    max-width:2.5vw;
    background-color:#0000;
    color:#fff;
    @media (min-width: 481px) and (max-width: 767px) {max-width:3vw;}
    @media (max-width: 481px) {max-width:5vw;}

    option {
      color:#000;
    }
  }
}
.Input:focus-within {
  border-color: #f60;
}