
.FileUpload {
  flex:1;

  .FileInputHidden {
    display: none;
  }

  .FileError {
    width:100%;
  }

  .FileNameInput {
    flex:1;
    min-height:1.5vw;
    padding:0.5vw;
    background-color:transparent;
    color:#fff;
    border:1px solid #999;
    border-radius:0.5vw;
  }

  .UploadBtn {
    padding: 8px 12px;
    background-color: #171;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #191;
    }

  }
}
