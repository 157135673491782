
.AuthPanel {
  position:relative;
  width:100%;
  margin:1vw 0;

  .UserInfo {
    width:100%;
    border-image: linear-gradient(90deg, #9991, #999) 1;
    border-bottom: 1px solid;

    .Information {
      width:65%;

      .InfoLine {
        width:100%;

        .txtRed {width:20%;}
      }
    }

    .AvaImgBig {
      width:25%;
      border-radius:0.5vw;
    }

    .InfoParagraf {
      width:80%;
      margin:2vw 0;
      padding:1vw;
      border:1px dashed #171;
      border-radius:0.5vw;

      p {text-indent:1vw;}
    }
  }
  
  .SecurityPannel {
    width:100%;
    
    .FormPannel {
      width:100%;

      .Input {width:90%;}
    }

    .EnotherButtons {
      width:100%;
  
      .Button {
        width: calc(44% - 1vw);
        margin:1vw 0.25vw 0 0.25vw;
      }
    }
  
    .LoadIcon {width:2vw;}
  
    .Button {
      margin-top: 1vw;
      padding: 0.5vw;
      width: calc(90% - 0.5vw);
      color:#fff;
      border-radius: 0.5vw;
      cursor: pointer;
    }

    .ColorBtnGrn {
      border: 1px solid #191;
      background-color:#1711;
      
      &:hover {
        background-color: #1715;
      }
    }
    .ColorBtnRed {
      border: 1px solid #911;
      background-color:#7111;
      
      &:hover {
        background-color: #7115;
      }
    }

    .Description {
      width:calc(90% - 2vw - 2px);
      margin:1vw 0 0 0;
      padding:0 1vw;
      border:1px dashed #f60;
      border-radius:0.5vw;

      p {
        text-indent:1vw;
        text-align:justify;
      }
    }
  }
}