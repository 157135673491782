
.SearchDocs {
  width:100%;
  margin-top:0.5vw;
  padding-bottom:0.5vw;

  .SearchPannel {
    width:100%;

    .SearchInput {
      width:30%;
    }
  }
}