
.FinCalc {
  width:100%;
  margin:0.5vw 0;

  .FinYearSummary {
    width:calc(40% - 2.5vw - 4px);
    padding:0.5vw 1vw;
    margin:0 0.25vw;
    border:1px solid #444;
    border-radius:0.5vw;
    @media (min-width: 481px) and (max-width: 767px) {width:calc(65% - 2.5vw - 4px);}
    @media (max-width: 481px) {width:calc(100% - 2.5vw - 4px);}

    .FinYear {
      width:100%;
      min-height:2vw;

      .YearNow {
        width:30%;
        min-height:1.5vw;
        border-top:1px solid #f60;
        border-bottom:1px solid #f60;
      }

      .YearBtn {
        width:calc(30% - 2px);
        min-height:1.5vw;
        color:#999;
        border-radius:0.5vw;
        border:1px solid #444;
      }

      .GrnBtn {
        color:#fff;
        background-color:#171;
        border:1px solid #171;
        &:hover {
          font-size:120%;
          cursor: pointer;
        }
      }
    }

    .FinYearInfo {
      width:100%;
      min-height:1.5vw;

      .FinYearInfoGroup {
        width:100%;
        margin-top:1vw;
        font-size:110%;
      }
      .FinYearInfoTxt {width:70%;}
      .FinYearInfoVal {width:30%;}
    }
  }

  .FinCalcSection {
    width:calc(60% - 2.5vw - 4px);
    padding:0.5vw 1vw;
    margin:0 0.25vw;
    border:1px solid #444;
    border-radius:0.5vw;
    @media (min-width: 481px) and (max-width: 767px) {width:calc(35% - 2.5vw - 4px);}
    @media (max-width: 481px) {width:calc(100% - 2.5vw - 4px);}
  }
}