
.TagSlider {
  position:relative;
  width:calc(100% - 1vw);
  margin:0.5vw;

  .Slider{
    width:100%;

    .SliderTxt {
      position:absolute;
      top:2vw;
      left:5vw;
      min-height:3vw;
      background-color:#0008;
      padding:0 3vw;
      font-size:200%;
      border:1px solid #999;
      border-radius:0.5vw;
      @media (max-width: 767px) {
        left:2vw; font-size:150%;
      }

      .CountLine {
        bottom:0;
        background-color:#1115;
        border-radius:0.5vw;
  
        .Dot {
          width:2vw;
          min-height:0.25vw;
          margin:0.25vw;
          border:2px solid #f60a;
          border-radius:30%;
          @media (min-width: 481px) and (max-width: 767px) {
            width:3vw;
            min-height:0.5vw;
          }
          @media (max-width: 481px) {
            width:4vw;
            min-height:0.5vw;
          }
        }
  
        .ActDot {
          background-color:#fff9;
        }
      }
    }
    
    .SliderImgBtns {

      .SliderImgBtn {
        position:absolute;
        min-height:100%;
        border-radius:0.5vw;
      }
    }

    .L, .R {
      opacity:0.5;

      img {
        width:4vw;
        padding:0.5vw;
        opacity:0.5;
        @media (max-width: 767px) {width:6vw;}
      }

      &:hover {
        opacity:1;
        transition: opacity 0.25s;

        img {
          width:5vw;
          padding:0;
          opacity:1;
          transition: width 0.25s, padding 0.25s, opacity 0.25s;
          @media (max-width: 767px) {width:7vw;}
        }
      }
    }

    .L {
      left:0;
      background-image:linear-gradient(90deg, #000e,#0000);
    }
    .R {
      right:0;
      background-image:linear-gradient(90deg, #0000,#000e);
    }

    .Image {
      width:100%;
      border-radius:0.5vw;
    }
  
  }

  .SliderEdit {
    width:100%;

    .ThemeWrapper {
      width:100%;

      .InputsWrapper {
        width:100%;
        
        .Input {
          width:32%;
          @media (max-width: 767px){width:100%;}
        }
      }

      .ImagesWrapper {
        width:100%;

        .SliderImage {
          position:relative;
          width:calc(33% - 1% - 2px);
          min-height:10vw;
          margin:0.25vw 0.5%;
          border:1px dashed transparent;
          @media (max-width: 767px){width:calc(50% - 1% - 2px);}
  
          img {
            width:100%;
          }

          .ActionBtns {
            position:absolute;
            width:100%;
            min-height:100%;
            
            .ActImgBtnL, .ActImgBtnR {
              position:absolute;
              width:2vw;
              padding:0 0.75vw;
              min-height:100%;
              background-color:#1113;
              &:hover {background-color:#1117;}
            }
            .ActImgBtnDel {
              position:absolute;
              top:0;
              right:0;
              background-color:#1115;
              border-radius:0.5vw;
            }
            .ActImgBtnL {left:0;}
            .ActImgBtnR {right:0;}
          }
        }
  
        .ActImg {
          border-color:#f60;
        }
      }

      .UploadFile {
        width:calc(33% - 1% - 1vw - 2px);
        min-height:11vw;
        margin:0.25vw 0.5%;
        @media (max-width: 767px){
          width:calc(50% - 1% - 1vw - 2px);
          min-height:17.5vw;
        }
      }
    }
  }
  
}