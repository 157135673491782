
.Document {
  width:calc(100% - 1vw);
  padding:1vw 0;
  @media print {
    width:calc(100% - 8vw);
    height:calc(100vh - 8vw);
    margin:0;
    padding:4vw;
    color:#000;
    background-color:#fff;
  }

  .PrintBtn {
    width:100%;
    margin:0.5vw 0;
    @media print {display:none;}
  }
}