
.DownloadBar {
  position:absolute;
  width:100%;

  .Bar {
    min-height:2.5vw;
    color:transparent;
    border-radius:0.5vw;
    @media (min-width: 481px) and (max-width: 767px) {min-height:4vw; border-radius:1vw;}
    @media (max-width: 481px) {min-height:6vw; border-radius:1.5vw;}
  }
}