
.DocumentLine {
  width:100%;

  .AddWeekBtn {
    flex:1;
    min-height:2vw;
    background-color:#171;
    border-radius:0.5vw;
    &:hover {
      font-weight:bold;
      background-image:linear-gradient(#1915, #191, #1915);
    }
    @media (max-width: 767px) { min-height:5vw; }
  }

  .ShowModeBtn {
    width:8vw;
    min-height:2vw;
    background-color:#fb0;
    border-radius:0.5vw;
    &:hover {
      font-weight:bold;
      background-image:linear-gradient(#fd05, #fd0, #fd05);
    }
    @media (max-width: 767px) {
      width:22%;
      min-height:5vw;
    }
  }

  .DownLoadBar {
    position:absolute;
    z-index:99;
    width:calc(100% - 0.5vw - 2px);
    margin:0.25vw 0 0 1px;
  }
      
  .IcoColor { filter: invert(100%); }
  .RepairBtn {
    position:absolute;
    top:0;
    right:0;
    width:1vw;
    height:1vw;
  }
}