
.TagContacts {
  width:100%;

  .el-L {
    width:calc(75% - 2px);
    border-right:1px dashed #f60;
    @media (min-width: 481px) and (max-width: 767px) {width:calc(60% - 2px);}
    @media (max-width: 481px) {
      width:100%;
      border-right:none;
      border-bottom:1px dashed #f60;
    }

    .GoogleMapsInput {
      width:100%;

      .Input {
        width:95%;
      }
    }

    iframe {
      width:calc(4.5vw * 16);
      height:calc(4.5vw * 9);
      opacity:0.8;
      margin:1vw 1vw 3vw 1vw;
      border-radius:0.5vw;
      @media (min-width: 481px) and (max-width: 767px) {
        width:calc(3.5vw * 16);
        height:calc(3.5vw * 9);
      }
      @media (max-width: 481px) {
        width:calc(5.5vw * 16);
        height:calc(5.5vw * 9);
      }
    }
  }

  .el-R {
    width:25%;
    padding:2vw 0;
    @media (min-width: 481px) and (max-width: 767px) {width:40%;}
    @media (max-width: 481px) {width:70%;}

    .Contact {
      width:95%;
      margin:0 0 1vw 0;

      .ContactLine {
        width:calc(100% - 1vw);
        padding:0.5vw;
        &:hover {
          .Content {font-size:110%;}
        }

        .ContactName {color:#f60;}
  
        .Content {
          width:100%;
  
          .InfoTxt {
            width:80%;
            min-height:2vw;
            @media (max-width: 767px) {min-height:4vw;}
          }
        }
      }
    }

    .ContactImages {
      width:100%;

      .ContactImg {
        width:50%;

        img {
          margin:2vw 3vw;
          width:30%;
          transition: all 0.3s ease-in-out;
          &:hover{
            margin:1vw 2vw;
            width:calc(30% + 2vw);
          }
        }
      }
    }
  }
}