
.UploadFile {
  width:calc(100% - 1vw - 2px);
  padding:1vw 0.5vw;
  border:1px dashed #171;
  border-radius:0.5vw;
  &:hover {
    border-color:#191;
    background-color:#1711;
  }

  .InputTag {
    width:100%;

    .FileName {
      width:calc(100% - 1vw - 2px);
      padding:0.25vw 0.5vw;
      color:#fff;
      background-color:transparent;
      border:1px solid #999;
      border-radius:0.5vw;
    }
  }

  .UplBtns {
    width:100%;
  
    .UplBtn {
      flex:1;
      color:#fff;
      min-height:2.5vw;
      margin:0.25vw 0;
      @media (min-width: 481px) and (max-width: 767px) {min-height:5vw;}
      @media (max-width: 481px) {min-height:7vw;}
    }
  }
}