
.Shadow {
  box-shadow: 0 0 0.5vw 0.5vw #999;
}

.EditArea {
  width:calc(100% - 2vw - 2px);
  margin:1vw;
  padding:0.5vw 0.5vw 1vw 0.5vw;
  border:1px solid #999;
  border-radius:0.5vw;
  @media print {
    border:none;
    box-shadow:none;
  }

  .ElFaults, .ElComments {
    width:calc(100% - 2px);
    margin:1vw 0 0 0;
    border:1px solid #999;
    border-radius:0.5vw;

    .FaultsTop, .CommentsTop {
      width:calc(100% - 4vw);
      min-height:3vw;
      padding:0 2vw;
      font-size:150%;
      background-color:#111;
      border-radius:0.5vw 0.5vw 0 0;
      @media print {background-color:#ddd;}
    }

    .TextAreaSection {
      width:100%;
      margin:0.5vw 0.25vw 1vw 0.25vw;
      min-height:2vw;

      .TextArea {
        min-height:8vw;
        @media (min-width: 481px) and (max-width: 767px) {min-height:20vw;}
        @media (max-width: 481px) {min-height:25vw;}
        @media print {min-height:8vw;}
      }

      .StrToLines {
        width:calc(100% - 2vw);
        padding:0 1vw;

        .Line {min-height:1vw;}
      }
    }
  }

  .ElSignatures {
    width:100%;
    margin:2vw 0 0 0;
  
    .Signature{
      width:calc(50% - 2vw);
      margin:1vw;
      text-align:center;

      .Person {
        min-height:2vw;
        font-size:120%;
      }
  
      .SignLine{
        width:80%;
        margin:0.5vw 0;
        border-bottom:1px dashed #999;
      }
    }
  }
}