

.CompaniesPannel {
  width:100%;
  margin:0 0 1vw 0;
  
  .CompaniesList {
    width:40%;
    @media (max-width: 767px) {width:98%;}

    .CompanyLine {
      width:calc(100% - 2vw);
      margin:0.5vw;
      padding:0.5vw;
      min-height:2.5vw;
      border:1px dashed #191;
      border-radius:0.5vw;

      .CompanyName {
        width:70%;
        font-size:150%;
        font-weight:bold;
      }
    }
    .CompanyLineOrg {border-color:#f60;}

    .NoLoginedUserWarning {
      width:90%;
      font-size:120%;
      color:#ddd;
      p {text-indent:1vw;}
    }
  }

  .ConpanyInfo {
    width:60%;
    @media (max-width: 767px) {width:98%;}

    .Company {
      width:calc(100% - 2vw);
      padding:0.5vw;
      margin:0.5vw;
      border:1px dashed #fd0;
      border-radius:0.5vw;

      .Input {width:100%;}

      .CompanyAva {
        width:15%;
        min-height:6vw;
        img {
          width:5vw;
          border-radius:0.5vw;
        }
      }

      .CompanyWarning {
        width:85%;
        min-height:6vw;
        position:relative;

        img {
          position:absolute;
          top:0;
          right:0;
        }
      }

      .CompanyTitle {
        width:100%;
        .TitleShortName {
          width:30%;
          @media (max-width: 767px) {width:100%;}
        }
        .TitleName {
          width:70%;
          @media (max-width: 767px) {width:100%;}
        }
      }
      .Digits {
        width:100%;
        .DigNip {
          width:20%;
          @media (max-width: 767px) {width:30%;}
        }
        .DigAcc {
          width:80%;
          @media (max-width: 767px) {width:70%;}
        }
      }
      .Address {
        width:100%;
        .AddrZip {
          width:20%;
          @media (max-width: 767px) {width:30%;}
        }
        .AddrTown {
          width:30%;
          @media (max-width: 767px) {width:70%;}
        }
        .AddrStreet {
          width:35%;
          @media (max-width: 767px) {width:85%;}
        }
        .AddrNr {
          width:15%;
          @media (max-width: 767px) {width:15%;}
        }
      }
      .Contacts {
        width:100%;
        .ContTel {
          width:20%;
          @media (max-width: 767px) {width:30%;}
        }
        .ContEmail {
          width:40%;
          @media (max-width: 767px) {width:70%;}
        }
        .ContWww {
          width:40%;
          @media (max-width: 767px) {width:100%;}
        }
      }

      .Personnel {
        width:100%;

        .Range {
          width:31%;
          margin:0.5vw 0 0 0;
          padding:0.5vw;
          border:1px solid #999;
          border-radius:0.5vw;
        }
      }
    }
  }
}