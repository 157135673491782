
.ElCalculator {
  width:calc(100% - 2px);
  margin:1vw 0 0 0;
  border:1px solid #999;
  border-radius:0.5vw;
  padding-bottom:1vw;

  .CalculatorPannelTop {
    width:100%;
    background-color:#111;
    border-radius:0.5vw 0.5vw 0 0;
    @media print {background-color:#ddd;}
  }

  .CalculatorPannelBottom {
    width:100%;
  }

  .BorderBottom {
    border-bottom:1px solid #444;
    @media print {border-bottom:1px solid #ddd;}
  }

  .CalcLine {
    width:99%;
    min-height:3vw;

    .TableCellTop {
      min-height:3vw;
    }
    .TableCell {
      min-height:1vw;
    }
    .TableCellBottom {
      min-height:1vw;
      margin:1vw 0 0 0;
    }
    .TableTOT {
      width:calc(35% - 4vw);
      padding:0.5vw 2vw;
      text-align:right;
      background-color:#111;
      @media (min-width: 481px) and (max-width: 767px) {padding:1vw 2vw; width:calc(20% - 4vw);}
      @media (max-width: 481px) {width:calc(35% - 4vw);}
      @media print {width:calc(35% - 4vw); background-color:#ddd;}
    }
    .TableNUM {
      width:3%;
      text-align:center;
      @media (min-width: 481px) and (max-width: 767px) {width:5%;}
      @media (max-width: 481px) {width:5%;}
      @media print {width:3%;}
    }

    .CellsBetween {
      flex: 1;

      .CellsOne {
        width:65%;
        @media (max-width: 767px) {min-height:5vw; width:100%; border-bottom:1px dashed #444;}
        @media print {width:65%; border:none;}

        .VATZWtext {
          width:100%;
          margin:1vw 0 0 0;
        }

        .VATbtns {
          width:100%;

          .CalcFuelBtn {
            width:25%;
            min-height:2.5vw;
            margin:0.5vw 0;
            border-radius:0.5vw;
            background-color: #1713;
            &:hover {background-color: #1715;}
            @media (min-width: 481px) and (max-width: 767px) {
              width:35%;
              min-height:3.5vw;
              border-radius:1vw;
            }
            @media (max-width: 481px) {
              width:50%;
              min-height:5vw;
              border-radius:1vw;
            }
          }
        }
  
        .TableART {width:100%; text-align:center;}
      }
      .CellsTwo {
        width:35%;
        @media (max-width: 767px) {min-height:5vw; width:100%;}
        @media print {width:35%; border:none;}
  
        .TableQUA, .TableVAT {
          width:14%;
          text-align:center;
          @media (min-width: 481px) and (max-width: 767px) {width:10%;}
          @media (max-width: 481px) {width:14%;}
          @media print {width:14%;}
        }
        
        .TablePRI, .TableNET, .TablePRV, .TableSUM {
          width:18%;
          text-align:center;
          @media (min-width: 481px) and (max-width: 767px) {width:12%;}
          @media (max-width: 481px) {width:18%;}
          @media print {width:18%;}
        }
      }
    }
    .TableBTN {
      width:5%;
      @media (min-width: 481px) and (max-width: 767px) {width:6%;}
      @media (max-width: 481px) {width:8%;}
      @media print {width:5%;}
    }

    input {
      width:100%;
      font-size:1vw;
      min-height:2vw;
      color:#fff;
      background-color:transparent;
      border:1px solid transparent;
      border-radius:0.25vw;
      &:hover {
        border:1px solid #f60;
      }
      &:focus {
        border:1px solid #171;
        background-color:#1712;
      }
      @media (min-width: 481px) and (max-width: 767px) {font-size:2vw !important;}
      @media (max-width: 481px) {font-size:2.5vw !important;}
      @media print {font-size:8px !important; color:#000;}
    }
  }
}