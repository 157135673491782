
.Pass {
  width:100%;
  padding:1vw 0;

  .UserArea {
    width:100%;

    .UserAtt {
      width:calc(100% - 2vw);
      margin:0.5vw;
      padding:0.5vw;
      border:1px dashed #f60;
      border-radius:0.5vw;
    }
    .ToolsPannel {
      width:calc(100% - 3vw);
      padding:0.5vw 1.5vw;

      .CounterTxt {
        width:50%;
        @media (max-width: 767px) {width:100%;}

        .txtOrg {margin:0 1vw 0 0.25vw;}
      }

      .Search {
        width:50%;
        margin:0.5vw 0;
        @media (max-width: 767px) {width:100%;}
  
        .Input {width:40%;}
      }
  
      .Groups {
        width:100%;
  
        .Group {
          width:calc((100% / 7) - 0.5vw - 2px);
          min-height:2vw;
          margin:0.25vw;
          border:1px solid #999;
          border-radius:0.5vw;
          @media (min-width: 481px) and (max-width: 767px) {
            width:calc((100% / 4) - 0.5vw - 2px);
            min-height:5vw;
          }
          @media (max-width: 481px) {
            width:calc((100% / 3) - 0.5vw - 2px);
            min-height:7vw;
          }
          &:hover {
            border-color:#fd0;
          }
        }
        .ActGroup {
          border-color:#fd0;
        }
      }
    }
  }

  .PassArea {
    width:100%;

    .PassEl {
      width:calc((100% / 5) - 2vw - 4px);
      min-height:2.5vw;
      margin:0.5vw;
      padding:0.5vw;
      background-color:#4655;
      border:1px solid #999;
      border-radius:0.5vw;
      @media (min-width: 481px) and (max-width: 767px) {
        width:calc((100% / 3) - 2vw - 4px);
        min-height:6vw;
      }
      @media (max-width: 481px) {
        width:calc((100% / 2) - 2vw - 4px);
        min-height:8vw;
      }
      &:hover {
        background-image: linear-gradient(#2221, #2229, #2221);
        border-color: #f60;
      }

      .PassName {
        width:100%;

        .SiteAva {width:15%;}
        .SiteName {width:85%;}
      }
    }

    .NewEl {
      background-color:#f605;
    }

    .EditEl {
      margin:0.5vw 0.5vw 0 0.5vw;
      padding:1vw 0.5vw 0.5vw 0.5vw;
      border-width:1px 1px 0 1px;
      border-radius:0.5vw 0.5vw 0 0;
    }

    .PassData {
      width:98%;
      margin:0 0.5vw;
      padding:0.5vw;
      background-color:#111;
      border:1px solid #999;

      .SiteName {
        width:25%;
        @media (min-width: 481px) and (max-width: 767px) {width:50%;}
        @media (max-width: 481px) {width:45%;}
        .Input {width:100%;}
      }

      .LinkBefore {
        width:40%;
        @media (max-width: 767px){display:none;}
        .Input {width:100%;}
      }

      .Group {
        width:20%;
        @media (min-width: 481px) and (max-width: 767px) {width:30%;}
        @media (max-width: 481px) {width:30%;}
        .Input {width:100%;}
      }

      .PassDataBtns {
        width:15%;
        margin:1vw 0 0 0;
        @media (min-width: 481px) and (max-width: 767px) {width:20%;}
        @media (max-width: 481px) {width:25%;}
      }

      .LinkAfter {
        display:none;
        width:100%;
        @media (max-width: 767px){display:flex;}
        .Input {width:100%;}
      }

      .Information {
        width:100%;
        margin:0.5vw 0;
        padding:0.5vw 0;
        border:1px solid #444;

        .TextArea {
          min-height:4vw;
          @media (max-width: 767px){min-height:12vw;}
        }
      }
    }

    .PassLines {
      width:100%;
      
      .PassLine {
        width:100%;
        margin:0.25vw 0;
        padding:0 0 0.5vw 0;
        border-top:1px dashed #999;
        border-bottom:1px dashed #999;

        .UserLoginPannel {
          width:90%;
          @media (min-width: 481px) and (max-width: 767px) {width:85%;}
          @media (max-width: 481px) {width:80%;}

          .UserName {
            width:30%;
            @media (max-width: 767px) {width:50%;}
            .Input {width:100%;}
          }
          .Login {
            width:30%;
            @media (max-width: 767px) {width:50%;}
            .Input {width:100%;}
          }
          .Password {
            width:40%;
            @media (max-width: 767px) {width:100%;}
            .Input {width:100%;}
          }
        }

        .LineBtns {
          width:10%;
          margin:1vw 0 0 0;
          @media (min-width: 481px) and (max-width: 767px) {width:15%;}
          @media (max-width: 481px) {width:20%;}
        }
      }
    }

    .AddNewPass {
      width:100%;
    }
  }
}