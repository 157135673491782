
.ElDocBtns {
  width:100%;

  .ActionBtns {
    width:30%;
    @media (min-width: 481px) and (max-width: 767px) {width:40%;}
    @media (max-width: 481px) {width:60%;}

    .ActionBtnsPannel {
      position:relative;
      width:50%;

      .ActionBtnsMenu {
        z-index:10;
        position:absolute;
        width:100%;
        background-color: #222;
        border-radius:0.5vw;
      }
    }

    .ColorBtnPannel {
      width:50%;
    }

    .ActBtn {
      position:relative;
      width:calc(100% - 0.5vw);
      min-height:3vw;
      margin:0.5vw 0.25vw;
      color:#999;
      background-color:#333;
      border-radius:0.5vw;
      &:hover {
        font-size:120%;
        color:#fff;
        background-image:linear-gradient(0deg, #1112, #111a 30% 70%, #1112)
      }
      @media (min-width: 481px) and (max-width: 767px) {min-height:4vw;}
      @media (max-width: 481px) {min-height:5vw;}

      input {
        position:absolute;
        width:100%;
        min-height:3vw;
        opacity:0;
        border: 0;
        cursor: pointer;
      }
    }
    .ActBtn_open {
      color:#fff;
      background-color:#0f07;
    }
    .ActBtn_repair {
      color:#fff;
      background-color:#00f7;
    }
    .ActBtn_close {
      color:#fff;
      background-color:#fd07;
    }
    .ActBtn_delete {
      color:#fff;
      background-color:#f007;
    }
  }

  .DocBtns {
    width:30%;
  }
}