
.DecoderNIP {
  width:100%;
  margin-top:0.5vw;
  padding-bottom:0.5vw;
  
  .ChgPartnerBtn {
    width:10%;
    min-height:calc(3vw + 3px);
    margin-top:1vw;
    background-color:#171;
    border-radius:0.5vw;
    @media (min-width: 481px) and (max-width: 767px) {
      width:45%;
      margin-top:calc(1vw + 3px);
      min-height:calc(7vw + 2px);
    }
    @media (max-width: 481px) {
      width:45%;
      margin-top:calc(1vw + 4px);
      min-height:calc(9vw + 4px);
    }
  }

  .NIPinput {
    width:30%;
    @media (max-width: 767px) {width:50%;}
  }
}