
.Workshop {
  position:relative;
  width:100%;
  flex:1;
  margin:0;

  .AdminBtn {
    position:sticky;
    top:0;
    width:100%;
    z-index:95;
    background-image:linear-gradient(#1111, #111e, #1111);

    .AdminInfo {
      flex:1;
      font-size:120%;
      text-align:center;
    }
  }

  .WorkshopInfo {
    width:95%;
    
    p {
      text-indent:2vw;
      font-size:120%;
      text-align:justify;
    }
  }

  .AdminBorder {
    width:100%;
    padding:0;
    border-top:1px solid transparent;
    border-bottom:1px solid transparent;
    &:hover {
      border-top:1px dashed #f60;
      border-bottom:1px dashed #f60;
    }
  }

  .Border {
    width:100%;
  }
  
  .Pattern {
    position:absolute;
    width:100%;
    min-height:100%;
    opacity:0.025;
    background-image: url('https://bzdrive.com/files/workshop/boardPattern.jpg');
    background-repeat: repeat;
    z-index:-1;
  }
}