
.CookiesPannel {
  position:fixed;
  bottom:0.5vw;
  width:80%;
  margin:0 10%;
  border-radius:0.5vw;
  background-color:#111e;
  box-shadow: 0 0 0.5vw 0.5vw #f60;
  @media (min-width: 481px) and (max-width: 767px) {
    bottom:1vw;
    width:96%;
    margin:0 2%;
    border-radius:1vw;
    box-shadow: 0 0 1vw 1vw #f60;
  }
  @media (max-width: 481px) {
    bottom:1vw;
    width:96%;
    margin:0 2%;
    border-radius:1vw;
    box-shadow: 0 0 1vw 1vw #f60;
  }

  .CookiesText {
    width:calc(80% - 2vw);
    padding:1vw;
    border-radius:0.5vw;
    @media (min-width: 481px) and (max-width: 767px) {
      width:calc(80% - 2vw);
      border-radius:1vw;
    }
    @media (max-width: 481px) {
      width:calc(70% - 2vw);
      border-radius:1vw;
    }
  }

  .CloseBtn {
    position:absolute;
    top:0.5vw;
    right:0.5vw;
  }

  .Paragraph {
    width:100%;
    margin:0.25vw 0;
    text-indent:2vw;
    text-align:start;
  }
}