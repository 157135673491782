
.FinanceMonth {
  width:100%;
  margin:0.5vw 0;

  .FinMonthSummary {
    width:calc(40% - 0.5vw - 4px);
    margin:0 0.25vw;
    padding:0 0 0.5vw 0;
    border:1px solid #444;
    border-radius:0.5vw;
    @media (min-width: 481px) and (max-width: 767px) {width:calc(65% - 0.5vw - 4px);}
    @media (max-width: 481px) {width:calc(100% - 0.5vw - 4px);}

    .TopLine {
      background-image:linear-gradient(#000, #0001);
      border-radius:0.5vw 0.5vw 0 0;
    }
    .Line {
      width:calc(100% - 1vw);
      padding:0 0.5vw;
      min-height:1.5vw;

      .LineDate {width:20%; padding:0.7vw 0;}
      .LineBtn {width:80%;}
      .LineGroup {
        width:100%;
        margin-top:1vw;
        font-size:110%;
      }
      .LineName {width:70%;}
      .LineVal {
        width:30%;

        input {
          width:50%;
          min-height:calc(1.2vw - 2px);
          margin:0;
          padding:0.25vw 0.5vw;
          text-align:end;
          font-size:1vw !important;
          color:#fff;
          background-color:transparent;
          border:1px solid #999;
          border-radius:0.25vw;
          &:focus {background-color:#1711;}
          @media (min-width: 481px) and (max-width: 767px) {min-height:2vw; font-size: 2vw !important;}
          @media (max-width: 481px) {min-height:3vw; font-size: 3vw !important;}
          @media print {color:#000 !important; font-size: 8px !important;}
        }
      }
    }
  }

  .FinMonthSection {
    width:calc(60% - 2.5vw - 4px);
    padding:0.5vw 1vw;
    margin:0 0.25vw;
    border:1px solid #444;
    border-radius:0.5vw;
    @media (min-width: 481px) and (max-width: 767px) {width:calc(35% - 2.5vw - 4px);}
    @media (max-width: 481px) {width:calc(100% - 2.5vw - 4px);}

    .FinWarning {
      width:100%;

      .WarningLine {
        width:100%;
      }
    }

    .ShowDocsBtn {
      width:40%;
      min-height:2vw;
      background-color: #171;
      border-radius:0.5vw;
      text-align:center;
      @media (min-width: 481px) and (max-width: 767px) {
        width:90%;
        min-height:5vw;
      }
      @media (max-width: 481px) {
        width:80%;
        min-height:7vw;
      }
    }
  }

  .FinMonthDocs {
    width:calc(100% - 2vw - 2px);
    margin:0.5vw 0 0 0;
    padding:0.5vw;
    border:1px solid #444;
    border-radius:0.5vw;

    .NewDocBtns {
      width:100%;
      margin:0.25vw 0;

      .NewDocBtn {
        width:calc(23% - 2px);
        min-height:3vw;
        margin:0.5vw;
        text-align:center;
        color:#fff;
        border:1px solid transparent;
        border-radius:0.5vw;
        @media (min-width: 481px) and (max-width: 767px) {
          width:calc(23% - 1vw - 2px);
          min-height:5vw;
        }
        @media (max-width: 481px) {
          width:calc(22% - 1vw - 2px);
          min-height:9vw;
        }
        &:hover{
          border:1px solid #999;
          font-weight: bold;
        }
      }
    }

    .FinDoscLineTop {
      width:100%;
      min-height:2vw;
      padding:0.5vw 0;
      background-color: #111;
      border-bottom:1px solid #444;
      border-radius:0.5vw 0.5vw 0 0;
    }
    .FinDoscLine {
      width:100%;
      min-height:2vw;
      border-bottom:1px solid #444;
    }
    .FinCell {
      min-height:2vw;
      padding:0 0.25%;
    }
    .FinNum {width:calc(5% - 0.5vw);}
    .Between {
      flex:1;
      max-width:95%;
      .FinNam {
        width:calc(20% - 0.5vw);
        @media (max-width: 767px) {flex:1;}
      }
      .FinUsr {
        width:calc(8% - 0.5vw);
        @media (max-width: 767px) {width:calc(20% - 0.5vw);}
      }
      .FinNet {
        width:calc(6% - 0.5vw);
        text-align:center;
        @media (min-width: 481px) and (max-width: 767px) {width:calc(13% - 0.5vw);}
        @media (max-width: 481px) {width:calc(16% - 0.5vw);}
      }
      .FinBru {
        width:calc(6% - 0.5vw);
        text-align:center;
        @media (min-width: 481px) and (max-width: 767px) {width:calc(13% - 0.5vw);}
        @media (max-width: 481px) {width:calc(16% - 0.5vw);}
      }
      .FinInf1 {
        flex:1;
        display:flex;
        @media (max-width: 767px) {display:none;}
      }
      .FinInf2 {
        width:calc(100% - 0.5vw);
        display:none;
        @media (max-width: 767px) {
          display:flex;
          color:#fd0;
        }
      }
      .FinInf2Top {
        width:calc(100% - 0.5vw);
        display:none;
        @media (max-width: 767px) {display:flex;}
      }
    }
    .FinBtn {
      width:calc(5% - 0.5vw);
      padding:0.25vw 0;
      text-align:center;
      @media (max-width: 767px) {width:calc(10% - 0.5vw);}
    }
    .FinDocColorZL {background-color: #7982;}
    .FinDocColorZU, .FinDocColorVA {background-color: #fd02;}
    .FinDocColorFS, .FinDocColorPS {background-color: #1712;}
    .FinDocColorFZ, .FinDocColorPZ {background-color: #f002;}
  }
}