
main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #999;

  .DownloadIcon {
    padding:3vw 0 0 0;
    width:25vw;
    height:25vw;
  }

  .SubLinks {
    width:100vw;

    .SubLink {
      width:30%;
      min-height:5vw;
      margin:1vw;
      border:1px dashed #555;
    }
  }
}