
.Finances {
  width:100%;
  margin:0 0 2vw 0;

  .Title {
    font-size:150%;
    margin:0 0 1vw 0;
  }

  .BottomLine {
    width:100%;

    .PrevBtn {
      width:15%;
      min-height:2vw;
      margin:1vw 2vw;
      background-color: #171;
      border-radius:0.5vw;
      &:hover {
        font-size:120%;
        cursor: pointer;
      }
      @media (min-width: 481px) and (max-width: 767px) {
        width:30%;
        min-height:5vw;
        margin:3vw;
      }
      @media (max-width: 481px) {
        width:50%;
        min-height:7vw;
        margin:5vw;
      }
    }
  }
}